import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, Store } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { ExpenseReportActions } from '../actions/expense-report.actions';
import { ExpenseReport } from '../models/expense-report.model';
import { ExpenseLine } from '../models/expense-line.model';
import { ExpensePhoto } from '../models/expense-photo.model';
import { ExpenseService } from 'src/app/services/expense.service';
import { patch, updateItem, removeItem } from '@ngxs/store/operators';
import { ConnectionStatusEnum, NetworkService } from 'src/app/services/network.service';
import { OfflineService } from 'src/app/services/offline.service';
import { DateTime } from 'luxon';
import { StorageService } from 'src/app/services/storage.service';

export class ExpenseReportStateModel {
    is_refreshing: boolean
    pending_count: number
    last_update: string
    expense_reports: ExpenseReport[]
}

@State<ExpenseReportStateModel>({
    name: 'expense_report',
    defaults: {
        is_refreshing: true,
        pending_count: 0,
        last_update: '',
        expense_reports: []
    }
})
@Injectable()
export class ExpenseReportState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private expenseService: ExpenseService,        
        private networkService: NetworkService,
        private offline: OfflineService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<ExpenseReportStateModel>) {   
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                        this.store.dispatch(new ExpenseReportActions.IncrementPendingCount())
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {         
        if (this.storageService.is_initialized) {
            const state_model: ExpenseReportStateModel = change.currentValue 
            this.storageService.set('expense_reports', JSON.stringify(state_model))    
        }                         
        if (this.networkService.currentStatus === ConnectionStatusEnum.Online) {   
            if (change.previousValue && change.previousValue.pending_count < change.currentValue.pending_count) {     
                const state_model: ExpenseReportStateModel = change.currentValue                     
                await this.offline.processPendingExpenses(state_model.expense_reports)                        
            }
        }     
    }

    async reloadStateFromStorage() {
        const json: string = await this.storageService.get('expense_reports');
        if (json) {
            const state_model: ExpenseReportStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new ExpenseReportActions.Init(state_model));
                return 
            }
        }
        this.store.dispatch(new ExpenseReportActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.expenseService.getMyExpenseReports()    
        if (objs != null) {
          const expenseReports = ExpenseReport.buildMany(objs)      

          const promises = []  
          for (const expenseReport of expenseReports) {
            for(const expenseLine of expenseReport.expense_lines) {
              if(expenseLine.has_receipt) {
                promises.push(this.getAndSetPhoto(expenseLine))
              }
            }
          }
          await Promise.all(promises)
          
          this.store.dispatch(new ExpenseReportActions.Merge(expenseReports))
          return true
        }
        return false
    }

    async getAndSetPhoto(expenseLine: ExpenseLine) {
        const photo = await this.expenseService.getPhotoByExpenseLineId(expenseLine.id)
        if (photo) expenseLine.photo = await ExpensePhoto.buildOne({...photo, has_server_photo: true})
    }

    @Selector()
    static getLastUpdate(state: ExpenseReportStateModel) {
        return state.last_update
    }

    @Selector()
    static getIsRefreshing(state: ExpenseReportStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getState(state: ExpenseReportStateModel) {
        return state
    }

    @Selector()
    static getAll(state: ExpenseReportStateModel) {
        return state.expense_reports
    }

    @Selector()
    static getOne(state: ExpenseReportStateModel) {
        return (id: number) => {
            return state.expense_reports.filter(s => s.id === id)
        }
    }

    @Action(ExpenseReportActions.Refresh)
    async refresh({patchState}: StateContext<ExpenseReportStateModel>, {  }:ExpenseReportActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(ExpenseReportActions.IncrementPendingCount)
    incrementPendingCount({patchState, getState}: StateContext<ExpenseReportStateModel>, {  }:ExpenseReportActions.IncrementPendingCount) {     
        const state = getState()
        patchState({
            pending_count: state.pending_count + 1
        })
    }

    @Action(ExpenseReportActions.Init)
    init({setState, getState}: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.Init) {             
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new ExpenseReportActions.Refresh())
    }

    @Action(ExpenseReportActions.Set)
    set({setState, getState}: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.Set) {        
        const state = getState()        
        setState({   
            is_refreshing: state.is_refreshing,  
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),         
            expense_reports: payload
        })
    }

    @Action(ExpenseReportActions.Merge)
    merge({getState, setState}: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.Merge) {   
        // This is to merge data from the API provided in payload, into the app state.
        // Basically, if we have server data, we trust that, so we want to replace the 
        // current app state with the payload, except:
        // 1. Any objects pending upload or delete need to be merged in, even if it no longer exists.
        const state = getState()
        const oldExpenses = [...state.expense_reports]
        let newExpenses = [...payload]        

        // For each expense in state now...
        for (const oldExpense of oldExpenses) {   
            let foundExistingServerVersion = payload.find(f => f.id === oldExpense.id)   

            // If expense id is not in the list from the server    
            if (!foundExistingServerVersion) {
                // If there are any pending save/delete, keep the entire object in state, else do not since no longer on server                
                if (oldExpense.is_pending_save || oldExpense.is_pending_delete) {                    
                    newExpenses.push(oldExpense)
                }
            } else {                
                // Object in state is in the payload from the server. 
                // 1. If anything is pending, we need to preserve the one in state, first removing the one that is from the server
                // 1. Else we leave the one from the server to set in the new state
                if (oldExpense.is_pending_save || oldExpense.is_pending_delete) {
                    // Remove one that is from the server
                    newExpenses = newExpenses.filter((obj) => {return obj !== foundExistingServerVersion});                        
                    // Add the one from state back in
                    newExpenses.push(oldExpense)
                } else {
                    // If expense is not pending but existed before, update the server object with the uuid from the state                     
                    foundExistingServerVersion.uuid = oldExpense.uuid
                }
            }
        }

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            expense_reports: newExpenses
        })
    }

    @Action(ExpenseReportActions.AddOne)
    addOne({getState, patchState}: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.AddOne) {
        const state = getState()        
        patchState({
            expense_reports: [...state.expense_reports, payload]
        })
    }

    @Action(ExpenseReportActions.UpdateOne)
    updateOne({setState}: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.UpdateOne) {
        setState(
            patch<ExpenseReportStateModel>
                ({
                    expense_reports: updateItem((item: ExpenseReport) => item.id === payload.id, 
                        patch<ExpenseReport>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(ExpenseReportActions.DeleteOne)
    deleteOne({setState}: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.DeleteOne) {
        setState(
            patch<ExpenseReportStateModel>
                ({
                    expense_reports: updateItem((item: ExpenseReport) => item.id === payload.id, 
                        patch<ExpenseReport>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(ExpenseReportActions.ApiAddedOne)
    apiAddedOne({ setState }: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.ApiAddedOne) {         
        setState(
            patch<ExpenseReportStateModel>
                ({
                    expense_reports: updateItem((item: ExpenseReport) => item.uuid === payload.uuid, 
                        patch<ExpenseReport>
                            ({
                                id: payload.id,
                                is_pending_save: false,
                                expense_lines: payload.expense_lines
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(ExpenseReportActions.ApiUpdatedOne)
    apiUpdatedOne({ setState }: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.ApiUpdatedOne) {         
        setState(
            patch<ExpenseReportStateModel>
                ({
                    expense_reports: updateItem((item: ExpenseReport) => item.id === payload.id, 
                        patch<ExpenseReport>
                            ({
                                is_pending_save: false,
                                expense_lines: payload.expense_lines
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(ExpenseReportActions.ApiDeletedOne)
    apiDeletedOne({ setState }: StateContext<ExpenseReportStateModel>, { payload }:ExpenseReportActions.ApiDeletedOne) {         
        setState(
            patch<ExpenseReportStateModel>
                ({
                    expense_reports: removeItem((item: ExpenseReport) => item.id === payload.id),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

}

import { ExpenseCategory } from "../models/expense-type.model";
import { ExpenseCategoryStateModel } from "../state/expense-type.state";

export namespace ExpenseCategoryActions {
    export class Refresh {
        static readonly type = '[API-ExpenseCategory] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-ExpenseCategory] Init'
        constructor(public payload: ExpenseCategoryStateModel) {}
    }
    export class Set {
        static readonly type = '[API-ExpenseCategory] Set'
        constructor(public payload: ExpenseCategory[]) {}
    }
}

import { v4 as uuid } from 'uuid'
import { ExpenseLine } from './expense-line.model'

export class ExpenseReport {
  uuid: string
  id: number  
  is_pending_save: boolean
  is_pending_delete: boolean
  date_submitted: string
  date_submitted_tz: string
  expense_report_number: string
  expense_status: string
  expense_status_id: number
  total: number
  last_modified_date: string
  tz: string
  expense_lines: ExpenseLine[] = [];
   
  constructor() {
  }

  public async setViewModelFields()
  {
    if (!this.id) {
      this.id = 0
    }
    if (this.uuid === undefined) {
      this.uuid = uuid();
    }
    if (!this.expense_status) {
      this.expense_status = ''
    }
    if (this.is_pending_save === undefined) {
      this.is_pending_save = false
    }
    if (this.is_pending_delete === undefined) {
      this.is_pending_delete = false
    }
  }

  static buildOne(obj: any): ExpenseReport {
    let target: ExpenseReport = Object.assign(new ExpenseReport(), obj)  
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): ExpenseReport[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(ExpenseReport.buildOne(objs[i]))
      }
    }
    return lobjs
  }

  static mergeInto(expenseReport: ExpenseReport, expenseLines: ExpenseLine[]) {
    const expense_lines = expenseReport.expense_lines.map(existing_expense_line => 
      expenseLines.find(merge_expense_line => merge_expense_line.uuid === existing_expense_line.uuid || 
        (merge_expense_line.id > 0 && existing_expense_line.id > 0 && merge_expense_line.id === existing_expense_line.id)) || existing_expense_line)
      
    const new_expense_lines = expenseLines.filter(e => e.id === 0)
    if(new_expense_lines.length > 0) {
      expense_lines.push(...new_expense_lines)
    }

    const updated_expense_report = ExpenseReport.buildOne({
      ...expenseReport,
    })
    updated_expense_report.is_pending_save = true,
    updated_expense_report.expense_lines = expense_lines
    return updated_expense_report;
  }

}


import { ExpenseAndPhoto } from "../models/expense-line.model";
import { ExpenseReport } from "../models/expense-report.model";
import { UuidAndIdAndExpenseLines } from "../models/uuid-and-id-and-expense-lines";
import { ExpenseReportStateModel } from "../state/expense-report.state";

export namespace ExpenseReportActions {
    export class Refresh {
        static readonly type = '[API-Expense-Report] Refresh'
        constructor() {}
    }
    export class IncrementPendingCount {
        static readonly type = '[Network Service-Expense] Increment Pending Count'
        constructor() {}
    } 
    export class Init {
        static readonly type = '[API-Expense-Report] Init'
        constructor(public payload: ExpenseReportStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Expense-Report] Set'
        constructor(public payload: ExpenseReport[]) {}
    }
    export class Merge {
        static readonly type = '[API-Expense-Report] Merge'
        constructor(public payload: ExpenseReport[]) {}
    }
    export class AddOne {
        static readonly type = '[UI-Expense-Report] Add One'
        constructor(public payload: ExpenseReport) {}
    }   
    export class UpdateOne {
        static readonly type = '[UI-Expense-Report] Update One'
        constructor(public payload: ExpenseReport) {}
    }       
    export class DeleteOne {
        static readonly type = '[UI-Expense-Report] Delete One'
        constructor(public payload: ExpenseReport) {}
    } 
    export class DeletePhotoFromExpenseReport {
        static readonly type = '[UI-Expense-Report] Delete Photo from Expense Report'
        constructor(public payload: ExpenseAndPhoto) {}
    }
    export class ApiAddedOne {
        static readonly type = '[API-Expense-Report] Added One'
        constructor(public payload: UuidAndIdAndExpenseLines) {}
    }
    export class AddPhotoToExpenseReport {
        static readonly type = '[API-Expense-Report] Added Photo to Expense Report'
        constructor(public payload: ExpenseAndPhoto) {}
    }
    export class ApiUpdatedOne {
        static readonly type = '[API-Expense-Report] Updated One'
        constructor(public payload: UuidAndIdAndExpenseLines) {}
    }
    export class ApiDeletedOne {
        static readonly type = '[API-Expense-Report] Deleted One'
        constructor(public payload: UuidAndIdAndExpenseLines) {}
    }
}

import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { NetworkService } from './services/network.service';
import { ServerVersionService } from './services/server-version.service';
import { SwUpdate } from '@angular/service-worker';
import { ApiService } from './services/api.service';
import { Select, Store } from '@ngxs/store'
import { Observable, firstValueFrom } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { JsaState, JsaStateModel } from 'src/app/state-man/state/jsa.state'
import { JsaActions } from 'src/app/state-man/actions/jsa.actions'
import { AppConfig } from './state-man/models/app-config.model';
import { AppConfigState } from './state-man/state/app-config.state';
import { WorActions } from './state-man/actions/wor.actions';
import { FormActions } from './state-man/actions/form.actions';
import { DsrActions } from './state-man/actions/dsr.actions';
import { MrfActions } from './state-man/actions/mrf.actions';
import { AppConfigActions } from './state-man/actions/app-config.actions';
import { LoggerService } from './services/logger.service';
import { StorageService } from './services/storage.service';
import { FormState } from './state-man/state/form.state';
import { DsrState } from './state-man/state/dsr.state';
import { PtoState } from './state-man/state/pto.state';
import { MrfState } from './state-man/state/mrf.state';
import { DailyWorkState } from './state-man/state/daily-work.state';
import { WorkOrderState } from './state-man/state/work-order.state';
import { TechnicianState } from './state-man/state/technician.state';
import { VehicleState } from './state-man/state/vehicle.state';
import { FormTypeState } from './state-man/state/form-type.state';
import { SalesOrderItemState } from './state-man/state/sales-order-item.state';
import { WorState } from './state-man/state/wor.state';
import { AreaState } from './state-man/state/area.state';
import { CustomerState } from './state-man/state/customer.state';
import { ContactState } from './state-man/state/contact.state';
import { LocationState } from './state-man/state/location.state';
import { UnitState } from './state-man/state/unit.state';
import { JobTypeState } from './state-man/state/job-type.state';
import { WorkScheduleState } from './state-man/state/work-schedule.state';
import { Mutex } from 'async-mutex';
import { AppActions } from './state-man/actions/app.actions';
import { UiService } from './services/ui.service';
import { ExpenseReportState } from './state-man/state/expense-report.state';
import { ExpenseReportActions } from './state-man/actions/expense-report.actions';
import { ExpenseCategoryState } from './state-man/state/expense-type.state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @Select(UserState.getUser) user$: Observable<User>
  @Select(JsaState.getState) jsaState$: Observable<JsaStateModel>  
  @Select(AppConfigState.getAppConfig) appConfig$: Observable<AppConfig>
  @Select(PtoState.getIsRefreshing) isRefreshingPtoState$: Observable<boolean>
  @Select(JsaState.getIsRefreshing) isRefreshingJsaState$: Observable<boolean>
  @Select(MrfState.getIsRefreshing) isRefreshingMrfState$: Observable<boolean>  
  @Select(DsrState.getIsRefreshing) isRefreshingDsrState$: Observable<boolean>
  @Select(DailyWorkState.getIsRefreshing) isRefreshingDailyWorkState$: Observable<boolean>
  @Select(WorkOrderState.getIsRefreshing) isRefreshingWorkOrderState$: Observable<boolean>
  @Select(TechnicianState.getIsRefreshing) isRefreshingTechnicianState$: Observable<boolean>
  @Select(FormState.getIsRefreshing) isRefreshingFormState$: Observable<boolean>
  @Select(VehicleState.getIsRefreshing) isRefreshingVehicleState$: Observable<boolean>
  @Select(FormTypeState.getIsRefreshing) isRefreshingFormTypeState$: Observable<boolean>
  @Select(SalesOrderItemState.getIsRefreshing) isRefreshingSalesOrderItemState$: Observable<boolean>
  @Select(WorState.getIsRefreshing) isRefreshingWorState$: Observable<boolean>
  @Select(AreaState.getIsRefreshing) isRefreshingAreaState$: Observable<boolean>
  @Select(CustomerState.getIsRefreshing) isRefreshingCustomerState$: Observable<boolean>
  @Select(ContactState.getIsRefreshing) isRefreshingContactState$: Observable<boolean>
  @Select(LocationState.getIsRefreshing) isRefreshingLocationState$: Observable<boolean>
  @Select(UnitState.getIsRefreshing) isRefreshingUnitState$: Observable<boolean>
  @Select(JobTypeState.getIsRefreshing) isRefreshingJobTypeState$: Observable<boolean>
  @Select(WorkScheduleState.getIsRefreshing) isRefreshingWorkScheduleState$: Observable<boolean>
  @Select(ExpenseReportState.getIsRefreshing) isRefreshingExpenseReportState$: Observable<boolean>
  @Select(ExpenseCategoryState.getIsRefreshing) isRefreshingExpenseCategoryState$: Observable<boolean>
  state_version:string
  is_refreshing: boolean = false
  block_mutex: Mutex
  pto_state_refreshing: boolean = true
  jsa_state_refreshing: boolean = true
  mrf_state_refreshing: boolean = true
  dsr_state_refreshing: boolean = true
  daily_work_state_refreshing: boolean = true
  work_order_state_refreshing: boolean = true
  technician_state_refreshing: boolean = true
  form_state_refreshing: boolean = true
  vehicle_state_refreshing: boolean = true
  form_type_state_refreshing: boolean = true
  sales_order_item_state_refreshing: boolean = true
  wor_state_refreshing: boolean = true
  area_state_refreshing: boolean = true
  customer_state_refreshing: boolean = true
  contact_state_refreshing: boolean = true
  location_state_refreshing: boolean = true
  unit_state_refreshing: boolean = true
  job_type_state_refreshing: boolean = true
  work_schedule_state_refreshing: boolean = true
  expense_report_state_refreshing: boolean = true
  expense_category_state_refreshing: boolean = true
  constructor(
    public networkService: NetworkService,
    private platform: Platform,
    public serverVersionService: ServerVersionService,
    private swUpdate: SwUpdate,
    private alertCtrl: AlertController,
    private apiService: ApiService,
    private store: Store,
    private storageService: StorageService,
    private uiService: UiService
  ) {
    this.block_mutex = new Mutex()
    this.state_version = '1.0.0'
    this.platform.ready().then(async () => {      
      this.networkService.startWatching();
      this.serverVersionService.startWatching();
    });
    window.addEventListener('load', () => {
      window.history.pushState(null, null!, window.location.href);
    });

    window.addEventListener('popstate', () => {
      window.history.pushState(null, null!, window.location.href);
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.updatePrompt();
      });
    }

    this.networkService.events.subscribe(async (status: any) => {
      try {
        this.networkStatusChange();
      } catch (error) {          
        LoggerService.log(error)   
        await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      } 

    });

    this.storageService.events.subscribe(async (status: any) => {
      if (this.storageService.is_initialized) {        
        const val: AppConfig = await firstValueFrom(this.appConfig$)        
        if (val?.state_version !== this.state_version) {
          LoggerService.log('need to clear storage and set state version')        
          await this.storageService.clear()
          this.store.dispatch(new AppConfigActions.Set({state_version: this.state_version}))
        } 
      } else {
        LoggerService.log('not initialized')
      }
    })   
    
    this.checkRefreshing()

    this.isRefreshingPtoState$.subscribe(async (val: boolean) => {      
      this.pto_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingJsaState$.subscribe(async (val: boolean) => {      
      this.jsa_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingMrfState$.subscribe(async (val: boolean) => {      
      this.mrf_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingDsrState$.subscribe(async (val: boolean) => {      
      this.dsr_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingDailyWorkState$.subscribe(async (val: boolean) => {      
      this.daily_work_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingWorkOrderState$.subscribe(async (val: boolean) => {      
      this.work_order_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingTechnicianState$.subscribe(async (val: boolean) => {      
      this.technician_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingFormState$.subscribe(async (val: boolean) => {      
      this.form_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingVehicleState$.subscribe(async (val: boolean) => {      
      this.vehicle_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingFormTypeState$.subscribe(async (val: boolean) => {      
      this.form_type_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingSalesOrderItemState$.subscribe(async (val: boolean) => {      
      this.sales_order_item_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingWorState$.subscribe(async (val: boolean) => {      
      this.wor_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingAreaState$.subscribe(async (val: boolean) => {      
      this.area_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingCustomerState$.subscribe(async (val: boolean) => {      
      this.customer_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingContactState$.subscribe(async (val: boolean) => {
      this.contact_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingLocationState$.subscribe(async (val: boolean) => {      
      this.location_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingUnitState$.subscribe(async (val: boolean) => {      
      this.unit_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingJobTypeState$.subscribe(async (val: boolean) => {      
      this.job_type_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingWorkScheduleState$.subscribe(async (val: boolean) => {      
      this.work_schedule_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingExpenseReportState$.subscribe(async (val: boolean) => {      
      this.expense_report_state_refreshing = val
      this.checkRefreshing()
    });
    this.isRefreshingExpenseCategoryState$.subscribe(async (val: boolean) => {      
      this.expense_category_state_refreshing = val
      this.checkRefreshing()
    });
  }

  async checkRefreshing() {
    const release = await this.block_mutex.acquire();
    try {
      if (    
        this.pto_state_refreshing
        || this.jsa_state_refreshing
        || this.mrf_state_refreshing
        || this.dsr_state_refreshing
        || this.daily_work_state_refreshing
        || this.work_order_state_refreshing
        || this.technician_state_refreshing
        || this.form_state_refreshing
        || this.vehicle_state_refreshing
        || this.form_type_state_refreshing
        || this.sales_order_item_state_refreshing
        || this.wor_state_refreshing
        || this.area_state_refreshing
        || this.customer_state_refreshing
        || this.contact_state_refreshing
        || this.location_state_refreshing
        || this.unit_state_refreshing
        || this.job_type_state_refreshing
        || this.work_schedule_state_refreshing
        || this.expense_report_state_refreshing
        || this.expense_category_state_refreshing
        ) 
      { 
        if (!this.is_refreshing) {
          this.is_refreshing = true
          this.store.dispatch(new AppActions.Set({is_refreshing: true}))          
          setTimeout(()=>{                          
            this.resetRefreshing()
          }, 5000);
        }
      }    
      else {
        if (this.is_refreshing) {
          this.is_refreshing = false;
          this.store.dispatch(new AppActions.Set({is_refreshing: false}))                
        }
      }    
    } catch(e) {
      this.store.dispatch(new AppActions.Set({is_refreshing: false}))
    } finally {
      release()
    }
  }

  resetRefreshing() {    
    this.is_refreshing = false;
    this.pto_state_refreshing = false
    this.jsa_state_refreshing = false
    this.mrf_state_refreshing = false
    this.dsr_state_refreshing = false
    this.daily_work_state_refreshing = false
    this.work_order_state_refreshing = false
    this.technician_state_refreshing = false
    this.form_state_refreshing = false
    this.vehicle_state_refreshing = false
    this.form_type_state_refreshing = false
    this.sales_order_item_state_refreshing = false
    this.wor_state_refreshing = false
    this.area_state_refreshing = false
    this.customer_state_refreshing = false
    this.contact_state_refreshing = false
    this.location_state_refreshing = false
    this.unit_state_refreshing = false
    this.job_type_state_refreshing = false
    this.work_schedule_state_refreshing = false
    this.expense_report_state_refreshing = false
    this.expense_category_state_refreshing = false
    this.store.dispatch(new AppActions.Set({is_refreshing: false}))
  }

  async networkStatusChange() {       
    this.store.dispatch(new JsaActions.IncrementPendingCount())
    this.store.dispatch(new MrfActions.IncrementPendingCount())
    this.store.dispatch(new DsrActions.IncrementPendingCount())
    this.store.dispatch(new FormActions.IncrementPendingCount())
    this.store.dispatch(new WorActions.IncrementPendingCount())
    this.store.dispatch(new ExpenseReportActions.IncrementPendingCount())
  }  

  updateConfirmed() {
    this.apiService.goHome();
  }

  updatePrompt() {
    this.alertCtrl.create({
      header: 'New Version Available',
      message: 'Click OK to update the software to the latest version',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.updateConfirmed();
          }
        }
      ]
    }).then(alert => alert.present());
  }
}

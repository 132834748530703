import { Photo } from './photo.model'
import { v4 as uuid } from 'uuid'

export class ExpensePhoto extends Photo {
  // Model
  expense_id = 0

  public async setViewModelFields()
  {
    if (this.photo_data && this.photo_type) {
      this.base64 = 'data:' + this.photo_type + ';base64,' + this.photo_data
      this.base64_sz = this.base64
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
  }

  constructor(
   ) {
    super()
  }

  static override async buildOne(obj: any): Promise<ExpensePhoto> {
    let target: ExpensePhoto = Object.assign(new ExpensePhoto(), obj)
    await target.setViewModelFields()
    return target
  }

  static override async buildMany(objs: any): Promise<ExpensePhoto[]> {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(await ExpensePhoto.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}


import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { ExpenseCategoryActions as ExpenseCategoryActions } from '../actions/expense-category.actions';
import { ExpenseCategory } from '../models/expense-type.model';
import { ExpenseCategoryService } from 'src/app/services/expense-category.service';
import { StorageService } from 'src/app/services/storage.service';

export class ExpenseCategoryStateModel {
    is_refreshing: boolean
    expense_category: ExpenseCategory[]
}

@State<ExpenseCategoryStateModel>({
    name: 'expense_category',
    defaults: {
        is_refreshing: true,
        expense_category: []
    }
})
@Injectable()
export class ExpenseCategoryState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private expenseCategoryService: ExpenseCategoryService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<ExpenseCategoryStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                     
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    } 

    async ngxsOnChanges(change: NgxsSimpleChange) {                       
        if (this.storageService.is_initialized) {
            const state_model: ExpenseCategoryStateModel = change.currentValue
            this.storageService.set('expense_categories', JSON.stringify(state_model))    
        }      
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('expense_categories');        
        if (json) {
            const state_model: ExpenseCategoryStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new ExpenseCategoryActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new ExpenseCategoryActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.expenseCategoryService.getAll()    
        if (objs != null) {
          const many = ExpenseCategory.buildMany(objs)                  
          this.store.dispatch(new ExpenseCategoryActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: ExpenseCategoryStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: ExpenseCategoryStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: ExpenseCategoryStateModel) {
        return state.expense_category
    }

    @Action(ExpenseCategoryActions.Refresh)
    async refresh({patchState}: StateContext<ExpenseCategoryStateModel>, {  }:ExpenseCategoryActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }
    
    @Action(ExpenseCategoryActions.Init)
    init({setState, getState}: StateContext<ExpenseCategoryStateModel>, { payload }:ExpenseCategoryActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new ExpenseCategoryActions.Refresh())
    }

    @Action(ExpenseCategoryActions.Set)
    set({setState}: StateContext<ExpenseCategoryStateModel>, { payload }:ExpenseCategoryActions.Set) {             
        setState({    
            is_refreshing: false,        
            expense_category: payload
        })
    }
}

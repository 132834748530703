import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ExpenseReport } from '../state-man/models/expense-report.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';
import { ExpenseLine } from '../state-man/models/expense-line.model';
import { ExpensePhoto } from '../state-man/models/expense-photo.model';
import { PhotoService } from './photo.service';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getMyExpenseReports () : Promise<ExpenseReport[] | null> {
    try {      
      const ret = await this.apiService.apiReturnsAny('get', 'expenses/myExpenseReports/')        
      const expenseReports = (ret && Array.isArray(ret)) ? ExpenseReport.buildMany(ret) : null
      if (!expenseReports) return null

      const promises = []
      for (const expenseReport of expenseReports) {
        promises.push(this.getAndSetChildren(expenseReport))
      }
      await Promise.all(promises) 

      return expenseReports      
    } catch (error) {
      LoggerService.log(error + 'expenses/mine/')
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  private async getAndSetChildren(expense_report: ExpenseReport) {
    const expense_lines = await this.getExpenseLines(expense_report.id)
    if (expense_lines != null) {
      expense_report.expense_lines = expense_lines
    } else {  
      throw new Error('Error getting dsr service entries!')
    }    
  }

  public async getExpenseLines(expense_report_id: number) : Promise<ExpenseLine[] | null> {
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'expenses/myExpenses/' + expense_report_id)          
      return (ret && Array.isArray(ret)) ? ExpenseLine.buildMany(ret) : null
    } catch (error) {
      LoggerService.log(error + 'expenses/myExpenses/')
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }
  }

  public async getPhotoByExpenseLineId(expense_line_id: number) : Promise<ExpensePhoto | null> {
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'expenses/expenseLinePhoto/' + expense_line_id)          
      return ret ? ExpensePhoto.buildOne(ret) : null
    } catch (error) {
      LoggerService.log(error + 'expenses/expense_line_photo/')
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }
  }

  public async addPhoto (expenseLine: ExpenseLine, photo: ExpensePhoto) : Promise<ExpensePhoto | null> {
    try {
      const body = new FormData()
      const base64Image = photo.base64_sz.split(';base64,').pop()
      const blob = PhotoService.b64toBlob(base64Image, photo.file_type)
      body.append('media_file', blob, 'media_file')
      return await this.apiService.apiReturnsAny('post', '/expenses/lines/media/' + expenseLine.id, body, 'multipart/form-data')          
    } catch (error) {
      LoggerService.log(error + '/jsa/media/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }
  }

  public extractMimeType(base64String: string) : string {
    const mimeType = base64String.match(/^data:(.*?);base64,/);
    
    if (mimeType && mimeType[1]) {
      return mimeType[1].toLowerCase();
    } else {
      // Return null or a default value if the pattern does not match
      console.warn("Failed to extract MIME type from the string.");
      return '';
    }
  }

  private async expenseReportFactory(expenseReport: ExpenseReport) {
    const newExpenseLines: ExpenseLine[] = []
    
    if (expenseReport.expense_lines) {
      for(const line of expenseReport.expense_lines) {
        if(!line.photo) newExpenseLines.push(line)
        else if(line.photo && line.is_photo_pending_save) {
          const newLine = ExpenseLine.buildOne(line)
          if (newLine.photo) {
            const photoType = this.extractMimeType(newLine.photo.base64)
            const base64Data = newLine.photo.base64.split(',')[1]; // Remove the data URI scheme part
            newLine.photo = await ExpensePhoto.buildOne({
              ...newLine.photo,
              photo_data: base64Data,
              photo_type: photoType
            })
            newLine.photo.base64_sz = ''
            newLine.photo.base64 = ''
          }
          newExpenseLines.push(newLine)
        } else {
          const newLine = ExpenseLine.buildOne(line)
          newLine.photo = undefined
          newExpenseLines.push(newLine)
        }
      }
    }

    const newExpenseReport = ExpenseReport.buildOne(expenseReport)
    newExpenseReport.expense_lines = newExpenseLines
    return newExpenseReport
  }

  public async addExpenseReport(expenseReport: ExpenseReport) : Promise<ExpenseReport | null> {
    try {
      const body = await this.expenseReportFactory(expenseReport)
      return await this.apiService.apiReturnsAny('post', 'expenses/', body, 'application/json')   
    } catch (error) {
      LoggerService.log(error + 'post expense/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }

  public async updateExpenseReport(expenseReport: ExpenseReport) : Promise<ExpenseReport | null> {
    try {
      const body = await this.expenseReportFactory(expenseReport)
      const ret = await this.apiService.apiReturnsAny('put', 'expenses/' + expenseReport.id, body, 'application/json')         
      return ret
    } catch (error) {
      LoggerService.log(error + 'put expense/')  
      console.error(error + 'put expense/')  
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async deleteExpenseReport(expense: ExpenseReport) : Promise<any> {
    try {
      const body = expense
      const ret = await this.apiService.apiReturnsAny('delete', 'expenses/' + expense.id, body, 'application/json')               
      return ret
    } catch (error) {
      LoggerService.log(error + 'delete expense/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async deleteExpenseLine(expense_line: ExpenseLine) : Promise<any> {
    try {
      const body = expense_line
      const ret = await this.apiService.apiReturnsAny('delete', 'expenses/lines/' + expense_line.id, body, 'application/json')               
      return ret
    } catch (error) {
      LoggerService.log(error + 'delete expense line/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  
  
}

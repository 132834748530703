export class ExpenseCategory {
  id: number
  name: string
  default_form_data: string
  
  constructor() {
  }

  static buildOne(obj: any): ExpenseCategory {
    let target: ExpenseCategory = Object.assign(new ExpenseCategory(), obj)
    return target
  }

  static buildMany(objs: any[]): ExpenseCategory[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(ExpenseCategory.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}


import { ExpenseLine } from "../models/expense-line.model";
import { UuidAndId } from "../models/uuid-and-id";

export namespace ExpenseLineActions {
    export class Refresh {
        static readonly type = '[API-Expense-Line] Refresh'
        constructor() {}
    }
    export class IncrementPendingCount {
        static readonly type = '[Network Service-Expense] Increment Pending Count'
        constructor() {}
    } 
    export class Set {
        static readonly type = '[API-Expense-Line] Set'
        constructor(public payload: ExpenseLine[]) {}
    }
    export class Merge {
        static readonly type = '[API-Expense-Line] Merge'
        constructor(public payload: ExpenseLine[]) {}
    }
    export class AddOne {
        static readonly type = '[UI-Expense-Line] Add One'
        constructor(public payload: ExpenseLine) {}
    }   
    export class UpdateOne {
        static readonly type = '[UI-Expense-Line] Update One'
        constructor(public payload: ExpenseLine) {}
    }       
    export class DeleteOne {
        static readonly type = '[UI-Expense-Line] Delete One'
        constructor(public payload: ExpenseLine) {}
    } 
    export class ApiAddedOne {
        static readonly type = '[API-Expense-Line] Added One'
        constructor(public payload: UuidAndId) {}
    }
    export class ApiUpdatedOne {
        static readonly type = '[API-Expense-Line] Updated One'
        constructor(public payload: UuidAndId) {}
    }
    export class ApiDeletedOne {
        static readonly type = '[API-Expense-Report] Deleted One'
        constructor(public payload: UuidAndId) {}
    }
}
